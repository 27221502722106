import { createApp } from 'vue'
import App from './App.vue'

import router from './router'
import "amfe-flexible"
import Vue3TouchEvents from "vue3-touch-events";
/**
 * 全局/公共样式
 */
import "./style/index.scss";

const app = createApp(App)

app.use(Vue3TouchEvents)
app.use(router)

app.mount('#app')
