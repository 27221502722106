import { createRouter, createWebHistory } from 'vue-router'

const router = createRouter({
  history: createWebHistory(import.meta.env.VITE_BASE_PATH),
  routes: [
    {
      path: "/",
      redirect: '/skip'
    },
    {
      path: "/skip",
      name: 'skip',
      component: () => import('@/views/skip/index.vue')
    },
    {
      path: "/home",
      name: 'home',
      component: () => import('@/views/home/index.vue')
    },
    {
      path: "/brand_nav",
      name: 'brand_nav',
      component: () => import('@/views/brand_nav/index.vue')
    },
    {
      path: "/brand_query",
      name: 'brand_query',
      component: () => import('@/views/brand_query/index.vue')
    },
    {
      path: "/nav_home",
      name: 'nav_home',
      component: () => import('@/views/nav_home/index.vue')
    },
    {
      path: "/sift",
      name: 'sift',
      component: () => import('@/views/sift/index.vue')
    },
    {
      path: "/error",
      name: 'error',
      component: () => import('@/views/error/index.vue')
    },

  ]
})

export default router